import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const MissionAndVission = () => {
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapDomainSection h1", {
        opacity: 0,
        duration: 1,
        x: 500,
        scrollTrigger: {
          trigger: ".gsapDomainSection h1",
          scroller: "body",
          markers: false,
          start: "top 55%",
        },
      });
    }
  });

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapMissionSection .MissionVissiongsapCard", {
        opacity: 0,
        duration: 1,
        x: 500,
        scrollTrigger: {
          trigger: ".gsapMissionSection .MissionVissiongsapCard",
          scroller: "body",
          markers: false,
          start: "top 55%",
        },
      });
    }
  });

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapMissionSection p", {
        opacity: 0,
        duration: 1,
        scale: 0.5,
        y: 20,
        delay: 1,
        scrollTrigger: {
          trigger: ".gsapMissionSection p",
          scroller: "body",
          markers: false,
          start: "top 55%",
        },
      });
    }
  });
  return (
    <div>
      <div
        style={{ ...webStyle.container, flexWrap: "wrap" }}
        className="fullWidth gsapMissionSection"
      >
        <div
          style={{ ...webStyle.card, flexDirection: "column" }}
          className="visionSectionCards MissionVissiongsapCard"
        >
          <div style={{ ...webStyle.titleDiv, textAlign: "center" }}  className="vibrate-text">
            <p style={webStyle.title}>Vision</p>
          </div>
          <p style={{...webStyle.paragraph,marginBottom:"70px"}}>
            To lead the industry in providing innovative, sustainable solutions
            that enhance plant growth and contribute to a greener, more
            sustainable world.
          </p>
          <button className="button"    onClick={() => history.push("/AboutUs")}><span>Read more</span></button>

        </div>
        <div
          style={{ ...webStyle.middleCard, flexDirection: "column" }}
          className="middleCard visionSectionCards MissionVissiongsapCard"
        >
          <div style={{ ...webStyle.titleDiv, textAlign: "center" }}  className="vibrate-text">
            <p style={webStyle.title}>Mission</p>
          </div>
          <p style={webStyle.paragraph}>
            Our mission is to deliver high-quality chelated micronutrients that
            empower farmers and industries to optimize their crop yields,
            improve soil health, and promote environmentally responsible farming
            practices.
          </p>
          <button className="button" onClick={() => history.push("/AboutUs")}><span>Read more</span></button>

        </div>
        <div
          style={{ ...webStyle.card, flexDirection: "column" }}
          className="promiseCard visionSectionCards MissionVissiongsapCard"
        >
          <div style={{ ...webStyle.titleDiv, textAlign: "center" }} className="vibrate-text">
            <p style={webStyle.title}>Promises</p>
          </div>
          <p style={{...webStyle.paragraph,marginBottom:"20px"}}>
            We promise to provide cutting-edge, sustainable products that not
            only improve agricultural productivity but also support long-term
            environmental well-being, fostering a future where growth and
            sustainability go hand in hand.
          </p>
          <button className="button"    onClick={() => history.push("/AboutUs")}><span>Read more</span></button>

        </div>
      </div>
    </div>
  );
};
const webStyle = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100vh",
    background:"#f2f2f2"

  },
  paragraph: {
    padding: "10px",
    fontSize: "16px",
    fontWeight: 500,
    color: "grey",
  },
  title: {
    fontWeight: 700,
    color: "white",
    fontSize: "30px",
    marginTop:"12px"
  },
  titleDiv: {
    borderRadius: "20px",
    textAlign: "center",
    height: "50px",
    width: "300px",
    background: "green",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "10px",
  },
  middleCard: {
    width: "350px",
    height: "380px",
    background: "#FFFFFF",
    borderRadius: "6px",
    margin: "0 50px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  card: {
    width: "350px",
    height: "380px",
    background: "#FFFFFF",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
};

export default MissionAndVission;
