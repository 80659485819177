import React, { useEffect, useState } from "react";
import { banner2 } from "../../Assets.tsx";
import { useHistory } from "react-router-dom";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const AboutSection = () => {
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapAboutText", {
        opacity: 0,
        duration: 2,
        x: -500,
        scrollTrigger: {
          trigger: ".gsapAboutText",
          scroller: "body",
          markers: false,
          start: "top 50%",
        },
      });
    }
  });
  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapVishwasImg", {
        opacity: 0,
        duration: 2,
        x: 500,
        scrollTrigger: {
          trigger: ".gsapVishwasImg",
          scroller: "body",
          markers: false,
          start: "top 50%",
        },
      });
    }
  });

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapAboutText2", {
        opacity: 0,
        duration: 2,
        x: -500,
        scrollTrigger: {
          trigger: ".gsapAboutText2",
          scroller: "body",
          markers: false,
          start: "top 50%",
        },
      });

    }
  });
  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapVishwasImg2", {
        opacity: 0,
        duration: 2,
        x: 500,
        scrollTrigger: {
          trigger: ".gsapVishwasImg2",
          scroller: "body",
          markers: false,
          start: "top 50%",
        },
      });

    }
  });

  return (
    <div
    className="fullWidth"

     style={{  width: "100%",
      height: "100vh",background:"#f2f2f2"}} >

    <h1
    style={{
      textAlign: "center",
      fontSize: "50px",
      color: "#0d803f",
      padding: "50px 0px",
    }}
    className="aboutus-title"
  >
    <span style={{ color: "#a6ce39" }}>About</span> Company
  </h1>
   
    <div
      style={{
      
        // borderBottom: "5px solid green",
        display: "flex", justifyContent: "center", 
        alignItems:"center",
        
      }}
    >
      {/* <h1
        style={{
          textAlign: "center",
          fontSize: "50px",
          color: "#0d803f",
          margin: "50px 0 50px 0px",
        }}
      >
        <span style={{ color: "#a6ce39" }}>About</span> Company
      </h1> */}
      <div
        style={{ display: "flex", justifyContent: "center", width: "100%" }}
        className="about_us_page"
      >
        <div
          style={{ width: "50%", marginRight: "50px" }}
          className=" aboutus gsapAboutText2"
        >
          <img
            src={banner2}
            alt="about-us"
            style={{ width: "700px", height: "450px",aspectRatio:"2",objectFit:"cover" }}
            className="fullWidth"
          />
        </div>
        <div
          style={{ width: "40%", marginBottom: "20px"}}
          className="about-us-paragraph gsapVishwasImg2"
        >
          <p style={{ fontWeight: 600, fontSize: "17px", color: "grey" }}>
           <span style={{fontWeight:700,fontSize: "18px",color:"black"}}> Welcome to Green Gold Industries!</span> We are at the forefront of
            agricultural innovation, offering cutting-edge chelated
            micronutrients designed to enhance growth and promote sustainable
            cultivation. Our initial focus on high-impact agricultural solutions
            is just the beginning; we plan to expand into premium sectors such
            as agrochemicals, fertilizers, and food products through a seamless
            integration of processes.
          </p>
          <p style={{ fontWeight: 600, fontSize: "17px", color: "grey" }}>
            Our products are crafted to deliver essential minerals with
            exceptional efficiency, ensuring optimal care for your crops.
            Committed to sustainability, we strive to boost agricultural
            productivity while contributing to a healthier planet.
          </p>
          <p style={{ fontWeight: 600, fontSize: "17px", color: "grey" }}>
            As a global leader, <span style={{fontWeight:700,fontSize: "18px",color:"black"}}> Green Gold Industries</span> proudly exports our
            innovative solutions worldwide, supporting agricultural
            sustainability on an international scale. Our dedication to
            innovation and quality makes us the ideal partner for achieving
            thriving, sustainable farming practices. Contact us to explore how
            we can support your agricultural success.
          </p>
          <button
            onClick={() => history.push("/AboutUs")}
            className="glow-on-hover"
            type="button"
          >
            Read more
          </button>
        </div>
      </div>
    </div>
    </div>
  );
};

export default AboutSection;
