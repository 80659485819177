import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Navbar from "../Navbar/Navbar.tsx";
import Footer from "../Footer/Footer.tsx";
import { banner2, mission, promises, vission } from "../../Assets.tsx";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const AboutusPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const history = useHistory();

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapAboutSection .CompanyTitle", {
        opacity: 0,
        duration: 1,
        x: 500,
        scrollTrigger: {
          trigger: ".gsapAboutSection .CompanyTitle",
          scroller: "body",
          markers: false,
          start: "top 55%",
        },
      });
    }
  });

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapAboutSection .gsapParagraph", {
        opacity: 0,
        duration: 1,
        scale: 0.5,
        y: 20,
        delay: 1,
        scrollTrigger: {
          trigger: ".gsapAboutSection .gsapParagraph",
          scroller: "body",
          markers: false,
          start: "top 55%",
        },
      });
    }
  });

  useGSAP(() => {
    if (!isMobile) {
      gsap.from(".gsapAboutSection .sectionsPromises", {
        opacity: 0,
        duration: 1,
        scale: 0.5,
        y: 20,
        delay: 1,
        scrollTrigger: {
          trigger: ".gsapAboutSection .sectionsPromises",
          scroller: "body",
          markers: false,
          start: "top 55%",
        },
      });
    }
  });
  useGSAP(() => {
    gsap.from(".gsapAboutUs", {
      y: -30,
      opacity: 0,
      duration: 3,
      stagger: 0.3,
      delay: 1,
    });
  });

  return (
    <div style={{ width: "100%", height: "auto" }} className="fullWidth">
      <Navbar />
      <div style={{ position: "relative", width: "100%" }} className="gsapAboutUs">
  <img
    src={banner2}
    alt="Banner"
    style={{ width: "100%", height: "100vh" }}
  />
  <div
    style={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      color: "#36f236",
      fontSize: "60px",
      fontWeight: "bold",
      textAlign: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      padding: "10px 20px",
      borderRadius: "5px",
      width: "80%",
      height: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <button
  onClick={() => history.push("/")}
        style={{
        position: "absolute",
        left: "20px",
        top: "20px",
        background: "green",
        border: "1px solid black",
        color: "white",
        borderRadius:"5px",
        fontSize: "18px",
        cursor: "pointer",
      }}
    >
      Back
    </button>
    About Us
  </div>
</div>


      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "650px",
        }}
        className="productSection fullWidth"
      >
        <div style={webStyle.paragraphboxShadow} className="gsapAboutSection missionVissionPadding">
          <p
            className="CompanyTitle"
            style={{ fontSize: "40px", fontWeight: 700, color: "green" }}
          >
            {" "}
            <span style={{ color: "black" }}>Welcome to </span> Green Gold
            Industries!
          </p>
          <p
            style={{ fontSize: "17px", color: "grey", textAlign: "justify" }}
            className="gsapParagraph"
          >
            At{" "}
            <span style={{ fontSize: "19px", fontWeight: 700, color: "black" }}>
              Green Gold Industries
            </span>{" "}
            , we're pioneering the future of agriculture with our cutting-edge
            chelated micronutrients, meticulously designed to optimize growth
            and drive sustainable cultivation. Our mission is to revolutionize
            the Agro Segment by initially focusing on high-impact solutions for
            agricultural growth and gradually expanding into premium agro-based
            sectors, including agrochemicals, fertilizers, and food products. We
            achieve this through a seamless integration of backward and forward
            processes, ensuring comprehensive solutions at every stage.
          </p>
          <p
            style={{ fontSize: "17px", color: "grey", textAlign: "justify" }}
            className="gsapParagraph"
          >
            Our advanced products are engineered to deliver essential minerals
            with unparalleled efficiency, providing your crops with the precise
            care they need. We're passionate about sustainability and committed
            to enhancing agricultural productivity while nurturing a healthier
            planet.
          </p>
          <p
            style={{ fontSize: "17px", color: "grey", textAlign: "justify" }}
            className="gsapParagraph"
          >
            Beyond local markets,{" "}
            <span style={{ fontSize: "18px", fontWeight: 700, color: "black" }}>
              Green Gold Industries
            </span>{" "}
            is a global leader, exporting our innovative solutions to support
            agricultural sustainability worldwide. Our international presence
            reflects our dedication to advancing global farming practices and
            delivering top-tier micronutrient solutions wherever they are
            needed.
          </p>
          <p
            style={{ fontSize: "17px", color: "grey", textAlign: "justify" }}
            className="gsapParagraph"
          >
            Driven by innovation, quality, and global service, Green Gold
            Industries is your trusted partner for achieving thriving and
            sustainable farming practices around the world. Connect with us to
            discover how our expertise can support your agricultural success.
          </p>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "650px",
        }}
        className="productSection fullWidth"
      >
        <div style={webStyle.paragraphboxShadow} className="gsapAboutSection missionVissionPadding">
          <div style={{ display: "flex", color: "black" }} className="sectionsPromises about_us_page">
            <div>
              <img
src={vission}                alt="mission"
                style={{ width: "500px", height: "350px",borderRadius:"20px" }}
                className="fullWidth"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "start",
                marginLeft: "30px",
              }}
              className="about_us_page promisesDiv"
            >
              <h1 style={{ color: "green", fontSize: "45px", fontWeight: 700 }}>
                Vision
              </h1>
              <p style={{ color: "grey", fontSize: "22px", fontWeight: 500 }}>
                To lead the industry in providing innovative, sustainable
                solutions that enhance plant growth and contribute to a greener,
                more sustainable world.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "650px",
        }}
        className="productSection fullWidth"
      >
        <div style={webStyle.paragraphboxShadow} className="gsapAboutSection missionVissionPadding">
          <div
            style={{
              display: "flex",
              color: "black",
              flexDirection: "row-reverse",
            }}
             className="sectionsPromises about_us_page"
          >
            <div>
              <img
              src={mission}
                alt="mission"
                style={{ width: "500px", height: "350px",borderRadius:"20px" }}
                className="fullWidth"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "start",
                marginRight: "30px",
              }}
              className="about_us_page missionDiv"
            >
              <h1 style={{ color: "green", fontSize: "45px", fontWeight: 700 }}>
                Mission
              </h1>
              <p style={{ color: "grey", fontSize: "22px", fontWeight: 500 }}>
                Our mission is to deliver high-quality chelated micronutrients
                that empower farmers and industries to optimize their crop
                yields, improve soil health, and promote environmentally
                responsible farming practices.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "650px",
        }}
        className="productSection fullWidth"
      >
        <div style={webStyle.paragraphboxShadow} className="gsapAboutSection missionVissionPadding">
          <div style={{ display: "flex", color: "black" }} className="sectionsPromises about_us_page">
            <div>
              <img
              src={promises}
                style={{ width: "500px", height: "350px",borderRadius:"20px" }}
                className="fullWidth"
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "start",
                marginLeft: "30px",
              }}
              className="about_us_page promisesDiv"
            >
              <h1 style={{ color: "green", fontSize: "45px", fontWeight: 700 }}>
                Promises
              </h1>
              <p style={{ color: "grey", fontSize: "22px", fontWeight: 500 }}>
                We promise to provide cutting-edge, sustainable products that
                not only improve agricultural productivity but also support
                long-term environmental well-being, fostering a future where
                growth and sustainability go hand in hand.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
const webStyle = {
  paragraphboxShadow: {
    width: "80%",
    height: "auto",
    backgroundColor: "white",
    boxShadow: "10px 10px 20px rgba(0, 0, 0, 0.2)",
    margin: "40px",
    padding: "40px",
    borderRadius: "8px",
  },
};

export default AboutusPage;
